<template>
  <el-card :class="{'know-list':true,'header-border-hidden':headerBorder}" v-loading="loading" :body-style="{
    'padding-top':headerBorder?'0px':null,
  }">
    <template slot="header">
      <slot name="header">
        <div slot="header" class="clearfix" style="display: flex;">
          <el-image :src="require('@/assets/images/problemList/标签@2x.png')" style="width: 24px; height: 24px"></el-image>
          <span style="color: #00957e">知识点标签</span>
        </div>
      </slot>
    </template>
    <div :style="{height: (height && !isNaN(height) ? height+'px' : (height || 'auto')),'overflow-y':(height ? 'auto' : 'hidden')}">
      <el-button
          @click="$handleRoute({tag_id:know.id == $route.query.tag_id?null:know.id})"
          class="know-item"
          round
          plain
          :type="know.id == $route.query.tag_id ? 'primary': ''"
          v-for="(know , index) in ListData"
          size="mini" :key="index"
          v-if="!showLimit || (showLimit && index < showLimit)"
      >
        {{ know.title }} <span class="num">{{know.count}}</span>
      </el-button>
      <p style="text-align: center;cursor: pointer;" @click="showLimit = 0" v-if="showLimit&&ListData.length > showLimit">查看更多</p>
      <p style="text-align: center;cursor: pointer;" @click="showLimit = 8" v-if="!showLimit&&ListData.length > 8">隐藏更多</p>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "knowPoints",
  props:{
    height:{
      type:[String,Number],
      default(){return null; },
    },
    type:{
      type:String,
      default(){return 'question';},
      validator(value){
        return ['question','questList'].includes(value);
      }
    },
    headerBorder: {type:Boolean,default(){return true;}},
  },
  data(){
    return {
      loading :false,
      ListData:[],
      showLimit:8,
      typeList:{
        question:'/api/v1_0_0.question/know',
        questList:'/api/v1_0_0.question_list/know',
      }
    };
  },
  mounted() {
    this.getKnowPointTags();
  },
  methods:{
    getKnowPointTags(){
      this.loading = true;
      this.$http.request({url:this.typeList[this.type],method:'post'}).then(({data})=>{
        this.ListData = [...data]
      }).finally(()=>{
        this.loading = false;
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep.know-list{
  .el-card__header{
    font-weight: 700;
  }
  .know-item{
    margin-left:0;
    font-size: 14px;
    color: #363636;
    padding: 5px 10px;
    .num{
      padding: 1px 6px;
      font-size: 12px;
      display: inline-block;
      background: #00957e;
      border-radius: 11px;
      color: #fff;
    }
  }
  .know-item:not(:last-child){
    margin-right:5px;
    margin-bottom: 10px;
  }
}

</style>