import request from "@/Utils/api"

// 题单列表
export function question_list (data) {
  return request({
    url: "api/v1_0_0.question_list",
    method: "post",
    data
  })
}

// 知识点标签
export function know (data) {
  return request({
    url: "api/v1_0_0.question_list/know",
    method: "post",
    data
  })
}


// 题目知识点标签
export function TMknow (data) {
  return request({
    url: "api/v1_0_0.question/know",
    method: "post",
    data
  })
}
// 题单列表筛选参数
export function list_params (data) {
  return request({
    url: "api/v1_0_0.question_list/list_params",
    method: "post",
    cache:true,
    data

  })
}
// 新建题单选项
export function create_params (data) {
  return request({
    url: "api/v1_0_0.question_list/create_params",
    method: "post",
    cache:true,
    data
  })
}

// 热门推荐
export function hot (data) {
  return request({
    url: "api/v1_0_0.question_list/hot",
    method: "post",
    data
  })
}

// 新建题单
export function create (data) {
  return request({
    url: "api/v1_0_0.question_list/create",
    method: "post",
    data
  })
}

// 题单收藏
export function collection (data) {
  return request({
    url: "api/v1_0_0.user/collect",
    method: "post",
    data
  })
}
//题单详情
export function detailList (data) {
  return request({
    url: "api/v1_0_0.question_list/detail",
    method: "post",
    data
  })
}
//加入题单
export function join_list (data) {
  return request({
    url: "api/v1_0_0.question_list/join_list",
    method: "post",
    data
  })
}
export function answer_result (data) {
  return request({
    url: "api/v1_0_0.question_list/answer_result",
    method: "post",
    data
  })
}
// 单个题单统计
export function statistics (data) {
  return request({
    url: "api/v1_0_0.question_list/statistics",
    method: "post",
    data
  })
}
// 单个题单答题用户统计列表
export function statistics_user (data) {
  return request({
    url: "api/v1_0_0.question_list/statistics_user",
    method: "post",
    data
  })
}
// 题目统计
export function one_statis (data) {
  return request({
    url: "api/v1_0_0.question/one_statis",
    method: "post",
    data
  })
}
// 比赛详情
export function bisaidetail (data) {
  return request({
    url: "api/v1_0_0.question_other/detail",
    method: "post",
    data
  })
}
// 比赛提交答题信息
export function bisaianswer (data) {
  return request({
    url: "api/v1_0_0.question_other/answer",
    method: "post",
    data
  })
}
// 答题信息保存缓存
export function bisaianswercache (data) {
  return request({
    url: "api/v1_0_0.question/answerCache",
    method: "post",
    data,
    message:false
  })
}
// 更新题单
export function update (data) {
  return request({
    url: "api/v1_0_0.question_list/update",
    method: "post",
    data
  })
}
// 单个比赛统计
export function bisaiStatistics (data) {
  return request({
    url: "api/v1_0_0.question_other/statistics",
    method: "post",
    data
  })
}
// 单个比赛答题用户统计列表
export function bisaiStatistics_user (data) {
  return request({
    url: "api/v1_0_0.question_other/statistics_user",
    method: "post",
    data
  })
}
export function bisaianswer_result (data) {
  return request({
    url: "api/v1_0_0.question_other/answer_result",
    method: "post",
    data
  })
}
export function rank_log_down (data) {
  return request({
    url: "api/v1_0_0.question_other/rank_log_down",
    method: "post",
    data
  })
}




