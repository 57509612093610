import request from "@/Utils/api";


// 比赛考试列表
export function matchList(data){
    return request({
        url: "api/v1_0_0.question_other",
        method: "post",
        data,
      });
}
// 比赛考试筛选
export function options(data){
    return request({
        url: "api/v1_0_0.question_other/options",
        method: "post",
        cache:true,
        data,
      });
}
// 知识点
export function Knowledge(data){
  return request({
      url: "api/v1_0_0.question_list/know",
      method: "post",
      data,
    });
}

// 知识点
export function matchInfo(data){
  return request({
      url: "/api/v1_0_0.question_other/info",
      method: "post",
      data,
    });
}

// 报名
export function join(data){
  return request({
      url: "api/v1_0_0.question_other/join",
      method: "post",
      data,
    });
}
// 取消报名
export function cancel_join(data){
  return request({
      url: "api/v1_0_0.question_other/cancel_join",
      method: "post",
      data,
    });
}
// 创建比赛作业考试
export function create(data){
  return request({
      url: "api/v1_0_0.question_other/create",
      method: "post",
      data,
    });
}
// 创建比赛作业考试
export function detail(data){
  return request({
      url: "api/v1_0_0.question_other/detail",
      method: "post",
      data,
    });
}
// 发布
export function publishBS(data){
  return request({
      url: "api/v1_0_0.question_other/publish",
      method: "post",
      data,
    });
}
// 删除
export function removeBS(data){
  return request({
      url: "api/v1_0_0.question_other/remove",
      method: "post",
      data,
    });
}
// 移除、删除比赛内的成员
export function is_cat(data){
  return request({
      url: "api/v1_0_0.question_other/is_cat",
      method: "post",
      data,
    });
}
// 发布比赛考试作业
export function updateField(data){
  return request({
      url: "/xapi/question.other/updateField",
      method: "get",
      params:data,
    });
}







