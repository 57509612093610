<template>
  <div class="problemList">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <el-button type="primary" size="small" @click="$router.back()"
        >返 回</el-button
      >
      <el-card class="box-card">
        <div style="display: flex; align-items: center">
          <div class="title">搜索考试</div>

          <el-input
            style="width: 30%; border-radius: 40px"
            placeholder="请输入"
            v-model="courseName"
            @change="search"
            class="input-with-select"
            :clearable="true"
            @keyup.enter.native="search"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </el-input>
        </div>
        <div class="content-left">
          <div class="table-content" v-loading="problemLoading">
            <!-- 我创建的考试 -->
            <div class="table" style="margin-top: 20px" v-loading="loading">
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                :row-key="(row) => row.id"
              >
                <el-table-column label="状态" prop="status" align="center">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.status == -2
                        ? "已结束"
                        : scope.row.status == -1
                        ? "未开始"
                        : "正在进行"
                    }}</span></template
                  >
                </el-table-column>
                <el-table-column
                  label="考试名称"
                  prop="title"
                  align="center"
                  width="260"
                >
                </el-table-column>
                <el-table-column label="开始时间" align="center" width="180">
                  <template slot-scope="scope">{{
                    scope.row.start_date
                  }}</template>
                </el-table-column>
                <el-table-column label="结束时间" align="center" width="180">
                  <template slot-scope="scope">
                    <span>{{ scope.row.end_date }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="知识点" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.know_count }}个</span>
                  </template>
                </el-table-column>
                <el-table-column label="时长" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.duration }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="权限" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.is_openStr }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="举办老师" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.teach_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="is_publish" label="是否发布">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.is_publish"
                      :active-value="1"
                      :inactive-value="2"
                      :disabled="scope.row.status == -2"
                      @change="setShowHome(scope.row, $event)"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  align="center"
                  fixed="right"
                  width="250"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      @click="
                        $handleRoute(
                          { other_id: scope.row.id },
                          'teachingExamInfo'
                        )
                      "
                      >查看</el-button
                    >
                    <el-button
                      type="text"
                      :disabled="
                        !$isPowers('examEdit') ||
                        scope.row.status == 1 || scope.row.status == -2 ||
                        scope.row.match_type == 2
                      "
                      size="small"
                      @click="
                        $handleRoute(
                          { other_id: scope.row.id },
                          'teachingExamEdit'
                        )
                      "
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      :disabled="!$isPowers('examCensus')"
                      size="small"
                      @click="
                        $handleRoute(
                          {
                            other_id: scope.row.id,
                            group_id: scope.row.group_id,
                            start_time: scope.row.start_date,
                            end_time: scope.row.end_date,
                          },
                          'teachingExamCensus'
                        )
                      "
                      >答题统计</el-button
                    >
                    <el-button
                      type="text"
                      :disabled="
                        scope.row.status == 1 || scope.row.status == -2 || scope.row.match_type == 2
                      "
                      size="small"
                      @click="
                        $handleRoute(
                          { other_id: scope.row.id },
                          'matchExamQuestion'
                        )
                      "
                      >题目管理</el-button
                    >
                    <el-button
                      :disabled="
                        !$isPowers('examDel') ||
                        scope.row.status == 1 || scope.row.status == -2 ||
                        scope.row.match_type == 2
                      "
                      @click="
                        $handleConfirm(
                          '你确定删除此条考试信息吗？',
                          '/xapi/question.other/delete',
                          { id: scope.row.id },
                          () => {
                            getList()
                          }
                        )
                      "
                      type="text"
                      size="small"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <div class="pagination" style="margin-top: 20px">
                <el-pagination
                  background
                  layout="total, sizes, prev, pager, next"
                  :total="total"
                  @current-change="currentChange"
          @size-change="handleSizeChange"

                  :page-size="pageSize"
                  :current-page="page"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <diy-dialog
      class="order-payment-dialog"
      :bodyStyle="{ 'padding-top': 0 }"
      ref="orderPayment"
      width="500px"
      :showFooter="false"
    >
      <order-sure
        type="base"
        @paySuccess="successPay"
        :detail="{ ...currentData, showResult: false }"
        :order_id="currentData.id"
      ></order-sure>
    </diy-dialog>
  </div>
</template>

<script>
import { updateField } from "@/api/match.js";

import Advertising from "@/components/Advertising/Advertising.vue";
import HotRecommend from "@/views/api/com/hotRecommend";
import ProblemListTable from "@/components/ProblemListTable/ProblemListTable.vue";
import { question_list, detailList } from "@/api/problemList.js";
import { orderCreate } from "@/api/common.js";
import { copyTD } from "@/api/topic.js";
import SearchFilter from "@/components/com/SearchFilter";
import OrderSure from "@/views/api/com/orderSure";
import KnowPoints from "@/views/api/com/knowPoints";
export default {
  components: {
    KnowPoints,
    SearchFilter,
    Advertising,
    HotRecommend,
    ProblemListTable,
    OrderSure,
  },
  data() {
    return {
      where: {},
      params: {},
      isClick: true,
      showMine: false,
      operationTable: {
        label: "题单名称",
        isSelection: false,
        time_str: true,
        tableData: [],
        total: 0,
      },
      page: 1,
      pageSize: 50,
      total: 0,
      loading: false,
      tableData: [],
      total: 0,

      //分页相关
      page: 1,
      page_size: 50,
      problemLoading: false,
      currentData: {},
      payType: "",
      courseName: "",
    };
  },
  watch: {
    isClick(v) {
      if (!v) {
        this.$nextTick(() => {
          this.isClick = true;
        });
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取考试列表
    getList() {
      this.loading = true;
      this.$http
        .post(
          `xapi/question.other/myList/type/2?page=` +
            this.page +
            "&page_size=" +
            this.pageSize,
          { keyword: this.courseName }
        )
        .then((res) => {
          console.log(res, "liebbbbb");
          this.loading = false;

          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    search() {
      console.log(this.courseName, "this.courseName");
      this.getList();
    },
    // 是否发布作业
    setShowHome(row, val) {
      console.log(val, "valll");
      this.$confirm(
        `是否确认${val == "1" ? "发布" : "取消发布"}该考试？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(async () => {
        let data = {
          field: "is_publish",
          id: row.id,
          value: val,
        };
        updateField(data)
          .then((res) => {
            this.$message({
              type: "success",
              message: val == "1" ? "发布成功" : "取消发布成功",
            });
          })
          .catch((err) => {
            console.log(err, "err");
            if (val == "2") {
              row.is_publish = 1;
            } else {
              row.is_publish = 2
            }
          });
      }).catch(()=>{
        console.log('取消发布')
        if (val == "2") {
              row.is_publish = 1;
            } else {
              row.is_publish = 2;
            }
      });
    },

    // 分页
    currentChange(e) {
      this.page = e;
      this.getList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },

    handleEditChange(v, ov, { row, column }) {
      this.$http
        .request({
          url: "/xapi/question.quest_list/updateField",
          params: {
            field: column.prop,
            id: row.id,
            value: v,
          },
        })
        .catch(() => {
          row[column.prop] = ov;
        });
    },

    routeUpdate() {
      this.where = { ...this.$route.query, ...this.$route.query.params };
    },
    newCopy(e) {
      this.currentData = e;
      this.payType = "copy";
      this.orderCreate(e, this.successPay);
    },
    handlerCopyTD(e) {
      this.problemLoading = true;
      copyTD({ question_list_id: e.id })
        .then((res) => {
          console.log(res.data.id);
          detailList({ id: res.data.id })
            .then((res) => {
              this.$store.state.topicType = {
                name: "编辑题单",
                detail: res.data,
              };
              this.$router.push({
                path: "/problemListOperate",
                query: { id: e.id },
              });
            })
            .finally((error) => {
              this.problemLoading = false;
            });
        })
        .catch(() => {
          this.problemLoading = false;
        });
    },
    questionList(param = {}) {
      this.problemLoading = true;

      question_list({
        ...this.where,
        ...this.params,
        ...(param || {}),
        page: this.page,
        page_size: this.page_size,
      })
        .then(({ data }) => {
          this.operationTable.total = data.total;
          this.operationTable.tableData = data.data;
          this.$store.commit("SET", { key: "headerValue.flag", value: false });
        })
        .finally(() => {
          this.problemLoading = false;
          this.tag_id = "";
        });
    },
    // currentChange(e) {
    //   this.page = e;
    //   this.questionList();
    // },
    setWherePrams(params, isOverride = false) {
      params || {};
      this.page = 1;
      if (isOverride) {
        this.params = params;
      } else {
        this.params = { ...this.params, ...params };
      }
      this.questionList();
    },
    goNewProblemList() {
      if (this.userInfo.type + "" === "2") {
        this.$handleRoute("teachingQuestListCreate");
      } else {
        this.$store.state.topicType = {
          name: "新建题单",
          detail: {},
        };
        this.$router.push({ path: "/problemListOperate" });
      }
    },
    orderCreate(e, callback) {
      if (!(this.userInfo.id > 0)) {
        this.$store.state.lodinShow = true;
      } else {
        if (e.is_buy + "" === "0") {
          //创建订单
          orderCreate({
            type: 3,
            id: e.id,
          }).then((res) => {
            //已支付
            if (res.data.is_pay + "" === "1") {
              this.$message({
                message: "支付成功",
                type: "success",
              });
              callback && callback();
            } else {
              this.currentData = res.data.order;
              this.$refs.orderPayment.visibled(true);
            }
          });
        } else if (e.is_buy + "" === "1") {
          callback && callback();
        }
      }
    },
    //下载
    download(e) {
      this.currentData = e;
      this.payType = "download";
      this.orderCreate(e, this.successPay);
    },
    //支付成功
    successPay() {
      this.problemLoading = false;
      if (this.payType === "download") {
        this.$tool.fileResponse(this.currentData.down_url);
      } else {
        this.handlerCopyTD(this.currentData);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box-card {
  margin-top: 20px;
  .title {
    margin-right: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
  }
  ::v-deep {
    .el-input__inner {
      border-radius: 40px 0 0 40px;
      height: 32px;
    }
    .el-table__row {
      .el-input__inner {
        border-radius: 4px !important;
        height: 32px;
      }
    }
    .el-input-group__append {
      border-radius: 0 40px 40px 0;
      background: #00957e;
      color: #ffffff;
    }
    .border-base {
      text-align: center;
      border-radius: 20px;
      padding: 10px;
      border: 1px solid #ccc;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.content-left {
  // width: 70%;
  width: 100%;
  .operate {
    margin: 2.2% 0;
    // margin-top: 40px;
    // margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-end;
  }
}
</style>