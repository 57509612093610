<template>
  <div class="ProblemListTable">
    <el-table
      ref="table"
      :data="operation.tableData"
      tooltip-effect="dark"
      style="width: 100%"
      height="400"
      @row-click="rowDblclick"
      @selection-change="handleSelectionChange"
      :row-style="{ height: '0' }"
      :cell-style="{ padding: '6px' }"
      :row-key="(row) => row.id"
      :header-cell-style="{
        height: '50',
        padding: '4px',
        background: '#F9F9F9',
      }"
    >
    <el-table-column type="selection" width="55" align="center" v-if="operation.isSelection" :reserve-selection="true">
      </el-table-column>
      <el-table-column :label="operation.label=='题单名称'?'题单号':'题目号'" align="center" width="80">
        <template slot-scope="scope">{{ scope.row.id }}</template>
      </el-table-column>
      <el-table-column :label="operation.label=='题单名称'?'题单':'题目'"  align="center" width="280">
        <template slot-scope="scope">
          <el-tooltip
            effect="dark"
            placement="top"
            :disabled="scope.row.title.length <= 16"
          >
            <template slot="content">
              <span class="ProblemList-name" v-html="scope.row.title"></span>
            </template>
            <span
              class="ProblemList-name shenluehao"
              v-html="scope.row.title"
            ></span>
          </el-tooltip>
          <!--          <el-tooltip effect="dark" :content="scope.row.title" placement="top" v-if="scope.row.title.length > 16">-->
          <!--            <span class="ProblemList-name">{{ scope.row.title.substring(0,16) + "..." }}</span>-->
          <!--          </el-tooltip>-->
          <!--          <span class="ProblemList-name" v-else>{{ scope.row.title }}</span>-->
        </template>
      </el-table-column>
      <!-- <slot name="topicType"></slot> -->
      <el-table-column label="来源" align="center">
        <template slot-scope="scope">
          <div
            class="btn"
            :style="{ background: scope.row.from_color }"
            v-if="scope.row.from_title"
          >
            {{ scope.row.from_title }}
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column label="难度" width="80" align="center">
        <template slot-scope="scope">
          <div
            class="btn"
            :style="{ background: scope.row.diff_color }"
            v-if="scope.row.diff_title"
          >
            {{ scope.row.diff_title }}
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="题目数" align="center">
        <template slot-scope="scope">
          <div  v-if="scope.row.question_num"> 
            {{ scope.row.question_num }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="可见范围" show-overflow-tooltip align="center">
        <template slot-scope="scope">
            {{ scope.row.group_names }}
        </template>
      </el-table-column>
      <el-table-column label="提交人数" align="center">
        <template slot-scope="scope">
            {{ scope.row.respondent_num }}
        </template>
      </el-table-column>
      <el-table-column label="创建人" align="center">
        <template slot-scope="scope">
            {{ scope.row.user_nickname }}
        </template>
      </el-table-column> -->
      <!-- <el-table-column label="年份" align="center" v-if="operation.time_str">
        <template slot-scope="scope">
          <div class="btn" :style="{background:scope.row.time_color}" v-if="scope.row.time_str">{{ scope.row.time_str}}</div>
           <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column label="难度" align="center">
        <template slot-scope="scope">
          <div class="btn" :style="{background:scope.row.diff_color}" v-if="scope.row.diff_title">{{ scope.row.diff_title }}</div>
          <div v-else>--</div>
        </template>
      </el-table-column> -->
      <slot></slot>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    isChoice: {
      default: () => false,
    },
    isClick: {
      default: () => true,
    },
    rowDblclickPath: {
      type: String,
      default: () => "",
    },
    operation: {
      type: Object,
      default: () => {
        return {
          time_str: true,
          isSelection: true, //要不要勾选框
          tableData: [],
          total: 0,
        };
      },
    },
    rowSelection: {
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      multipleSelection: [],
    };
  },
  watch: {
    "$props.rowSelection"(v) {
      //设置选中的行
      if ((v || []).length) {
        v.forEach((row) => {
          this.$refs.table.toggleRowSelection(row, true);
        });
      }
    },
  },
  mounted() {
    //设置选中的行
    if (this.rowSelection.length) {
      this.rowSelection.forEach((row) => {
        this.$refs.table.toggleRowSelection(row, true);
      });
    }
  },
  methods: {
    clearTable() {
      this.$refs.table.clearSelection();
    },
    rowDblclick(e) {
      if (!this.isClick) {
        return;
      }
      if (!localStorage.getItem("user_token")) {
        this.$store.state.lodinShow = true;
        return;
      }
      if (this.userType == 0) {
        this.$store.state.showBinding = true;
        return;
      }
      if (this.isChoice) {
        this.$emit("rowSubject", e);
        return;
      }

      this.$handleRoute({ id: e.id, is_buy: e.is_buy }, this.rowDblclickPath);
      // this.$handleRoute({ id: e.id,is_buy:e.is_buy },'topicEmpty')
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = val;
      this.$emit("selection", this.multipleSelection);
    },
  },
};
</script>

<style lang="less" scoped>
.ProblemListTable {
  .btn {
    background: #2d93f8;
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    padding: 0px 10px;
  }
  ::v-deep {
    .el-table tr {
      height: 51px;
    }
    .el-table::before {
      height: 0;
    }
    .el-table thead {
      color: #000;
    }
    .el-table__row {
      cursor: pointer;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #00957e;
      border-color: #00957e;
    }
    .el-checkbox__inner:hover {
      border-color: #00957e;
    }
  }
  text-align: center;
  .pagination {
    margin: 2rem 0;
  }

  .download {
    width: 20px;
    height: 20px;
  }
  .ProblemList-name {
    color: #00957e;
  }
}
</style>